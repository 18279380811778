import React from "react"

export default function Submit({ label, width = "w-36" }) {
  return (
    <>
      <div className="md:flex md:items-center mb-6">
        <div className="md:flex-wrap">
          <label
            className={`${width} block md:text-right font-bold mb-1 md:mb-0 pr-4`}
          ></label>
        </div>
        <div className="md:flex-1">
          <button
            className="w-full md:w-auto shadow bg-green-900 hover:bg-green-800 focus:shadow-outline
            focus:outline-none text-white font-bold py-2 px-4"
            type="submit"
          >
            {label}
          </button>
        </div>
      </div>
    </>
  )
}
