import React from "react"

export default function Alert({ data }) {
  return (
    <>
      {data.show ? (
        <div
          className={`text-white px-6 py-4 border-0 rounded relative mb-4 ${data.color}`}
        >
          <span className="text-xl inline-block mr-5 align-middle">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">{data.title}</b> {data.message}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
