import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FormPage from "../components/form-page"
import { graphql } from "gatsby"
import Image from "gatsby-image"

export default function Donate({ data }) {
  return (
    <>
      <FormPage data={data} />
    </>
  )
}

export const pageQuery = graphql`
  query DonateQuery {
    wpPage(id: { eq: "cG9zdDo0Mg==" }) {
      featuredImage {
        node {
          title
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
