import React from "react"

export default function BannerSubpages({ image, text }) {
  return (
    <section className="relative">
      <div className="relative">
        <div className="absolute inset-x-0" />
        <div className="">
          <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
              {image}
              <div className="absolute inset-0" />
            </div>
            <div className="relative  px-4 py-16 sm:px-6 sm:py-24 lg:py-60 backdrop-fade">
              <p className="md:h-42 mt-6 max-w-lg mx-auto text-center text-xl lg:text-3xl text-white sm:max-w-5xl drop-shadow-2xl glow"
                 dangerouslySetInnerHTML={{ __html: text }}
              >

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
