import React from "react"

export default function Input({ label, name, width = "w-36", type = "text" }) {
  const labelClassDiv =
    type === "textarea" ? "md:items-start" : "md:items-center"
  const labelClass = type === "textarea" ? "md:pt-2" : ""
  return (
    <>
      <div className={`md:flex ${labelClassDiv} mb-6`}>
        <div className="md:flex-wrap">
          <label
            className={`${width} ${labelClass} block md:text-right font-bold mb-1 md:mb-0 pr-4`}
          >
            {label}
          </label>
        </div>
        <div className="md:flex-1">
          {type === "textarea" ? (
            <textarea
              className="appearance-none border-2 border-gray-200 rounded h-48
            w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              name={name}
            />
          ) : (
            <input
              inputMode={type}
              className="appearance-none border-2 border-gray-200 rounded
            w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              name={name}
              type="text"
            />
          )}
        </div>
      </div>
    </>
  )
}
