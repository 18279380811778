export default function sendMail(event) {
  const formData = new FormData(event.currentTarget)
  event.preventDefault()
  const data = {}

  let message = ""

  function validEmail(email) {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
    return regex.test(email)
  }

  // quick dirty email
  for (let [key, value] of formData.entries()) {
    if (key === "email" && validEmail(value)) {
      data[key] = value
    } else if (value.length > 0 && key !== "email") {
      data[key] = value
    } else {
      //
    }
    message += key + " : " + value + "\n"
  }

  const fieldsLength = Object.values(data).length

  if (fieldsLength === 7) {
    data["name"] = data.firstName + " " + data.lastName
    data["from"] = data.email
    data["message"] = message

    try {

      fetch(window.location.origin, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      }) .then(() => alert("Thank you for your submission"))
        .catch((error) => alert(error));

      return {
        show: true,
        message: "Message Sent!",
        title: null,
        color: "bg-green-500",
        success: true,
      }
    } catch (error) {
      return {
        show: true,
        message: "Something is broken while sending the email.",
        title: "Error:",
        color: "bg-red-500",
        success: false,
      }
    }
  } else {
    return {
      show: true,
      message: "All fields are required and email format must be a valid.",
      title: "Error:",
      color: "bg-red-500",
      success: false,
    }
  }
}
