import React, { useState } from "react"
import Layout from "../components/layout"
import BannerSubpages from "../components/banner-subpages"
import Input from "../components/input"
import Submit from "../components/submit"
import { StaticImage } from "gatsby-plugin-image"
import sendMail from "../lib/send-mail"
import Alert from "../components/alert"
import Image from "gatsby-image";

export default function FormPage({ data}) {
  const pageData = data.wpPage.featuredImage.node
  const subject = pageData.title
  const image = (
    <Image
      fluid={pageData.localFile?.childImageSharp?.fluid}
      alt={pageData.title}
      className="h-full w-full object-fit"
    />
  );

  const text = pageData.description

  let [alertData, setMessage] = useState({ show: false })
  const handleSubmit = event => {
    setMessage(sendMail(event))
  }

  return (
    <>
      <Layout>
        <BannerSubpages image={image} text={text} />
        <h3 className="text-center mt-5 mb-2 text-2xl leading-8 font-semibold tracking-tigh sm:text-4xl">
          {subject}
        </h3>
        <form
          name={subject} method="post" data-netlify="true" data-netlify-honeypot="bot-field"
          className="w-full m-auto max-w-5xl p-5 relative"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="subject" value={subject} />
          <input type="hidden" name="form-name" value={subject} />
          <input type="hidden" name="bot-field" />

          <Alert data={alertData} />
          <Input label="First Name" name="firstName" />
          <Input label="Last Name" name="lastName" />
          <Input label="Email" name="email" type="email" />
          <Input label="Contact No" name="contact" />
          <Input label="Message" name="message" type="textarea" />
          <Submit label="Send An Intention" />
        </form>
      </Layout>
    </>
  )
}
